import Map from './map.vue';
import StatutBar from './statut_bar.vue';
import BarreStatut from './barre_statut/barre_statut.vue';
import Throttle from 'lodash/throttle'
import Debounce from 'lodash/debounce'
import Companies from './companies.vue'
import { Splitpanes, Pane } from 'splitpanes'

export default {
	data() {
    return {
    	center: {lat: 0, lng: 0},
      zoom: 8,
      settings: {
        minScrollbarLength: 60,
        scrollingThreshold : 5000,
        swipeEasing : false
      },
      mapHeight: 200,
      mapWidth: 100,
      q: ''
    }
  },
  watch:{
    q: function(){
      this.debounceQ()
    },

    //AS--> Quand on revient en mode 'result', on désélectionne l'entreprise qui aurait pû être sélectionnée dans un autre mode comme monentreprise
    "$store.state.ui.content": function(newValue, oldValue){
      if(newValue == 'result' && newValue != oldValue) this.$store.dispatch('setCompany', null);
    }
  },
  mounted(){},
  beforeDestroy: function () {},
  methods: {
    resetQ(){
      this.$store.dispatch('setQ', '');
    },
    open_menu(){
      this.$store.commit('UI_SET_MENU', true);
    },
    splitpanes_resize(event){
      this.$store.commit('UI_SET_MAP_SIZE', event[0].size);
    }
  },
  computed:{
    getQ: {
      // accesseur
      get: function () {
        return this.$store.state.filters.filters.q
      },
      // mutateur
      set: Debounce(function (newValue) {
        this.$store.dispatch('setQ', newValue);
      }, 500)
    },
    showMapOnly(){
      return this.$store.state.ui.sourcing.masquer_liste || this.$store.state.ui.mode_affichage == 'carte' || this.$store.state.ui.screenPdfOn
    }
  },
	components: {
    Map,
    StatutBar,
    BarreStatut,
    Companies,
    Splitpanes,
    Pane,
    MonEntreprise: () => import('./editions/monentreprise.vue'),
    AllFavoris: () => import('./favoris/allfavoris.vue'),
    Account: () => import('./account.vue'),
    Graph: () => import('./graph.vue'),
    Favoris: () => import('./favoris.vue'),
    Search: () => import('./search.vue'),
    Downloadd: () => import('./download.vue'),
    SurfacesPage: () => import('./surfaces_page.vue'),
    ParcelleFavoris: () => import('./parcelle/parcelle_favoris.vue'),
	}
}