import vueCustomScrollbar from 'vue-custom-scrollbar'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Arbo from './blocs/arbo.vue'
import Debounce from 'lodash/debounce'
import CustomSlider from './blocs/custom_slider.vue'
import Utils from '../common/utils.js';
import Checkbox from '../common/forms/Checkbox.vue';
import FiltreHeader from './filtres/_filtre_header.vue';
import CarreLegende from '../common/ui/carre_legende.vue';

export default {
	mixins: [Utils],
	data() {
    return {
    	dechet_qty: ['',''],
		legendOpened: [],
		formatter1: '{value}%',
		sliders:{}
    }
  },
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		},
		reset_filters(){
			this.$store.dispatch('resetParams');
		},
		formatData(value) {
			if (this.is_float) {
				return value / 100;
			} else {
				return value;
			}
		},
		getLegendeIcon(style){
			if(!style) return false
			let icon = false
			if(undefined != style.icon){
				icon = style.icon.substring(0, 4) != 'http' ? '/sourcing_icons/'+style.icon : style.icon
			}
			return icon
		},
		hasLegendeStyle(style){
			return style?.fillColor != undefined || style?.strokeColor != undefined
		},
		getLayerCouleurs(layer){
			if(layer.type == 'multi'){

				//AS--> Détermine le calque à afficher
				let zone_size = this.$store.state.ui.map_zone_google.areaActif;
				if(typeof zone_size != 'undefined'){
				  let layer_selected = null;
				  layer.layers.forEach(function(layer){
				    if(layer.max == '') layer.max = 99999999999999999999999999999999999;
				    if(layer.max > zone_size && (layer_selected == null || layer.max < layer_selected.max)) layer_selected = layer;
				  })

				  if(layer_selected && layer_selected.couleurs){
				  	//AS--> S'il n'y a qu'une définition de couleur et qu'elle n'a pas de légende
				  	if(layer_selected.couleurs.length == 1 && !layer_selected.couleurs[0].legende) return null;
				  	return layer_selected.couleurs;
				  }else{
				  	return null;
				  }
				}

				return null
			}else{
				return layer.couleurs;
			}
		},
		getSubLayerId(layer){
			if(layer.type == 'multi'){

				//AS--> Détermine le calque à afficher
				let zone_size = this.$store.state.ui.map_zone_google.areaActif;
				if(typeof zone_size != 'undefined'){
				  let layer_selected = null;
				  layer.layers.forEach(function(layer){
				    if(layer.max == '') layer.max = 99999999999999999999999999999999999;
				    if(layer.max > zone_size && (layer_selected == null || layer.max < layer_selected.max)) layer_selected = layer;
				  })
				  return layer_selected._id
				}

				return null
			}else{
				return layer.layer;
			}
		},
		switchLegendOpened(i){
			if(this.legendOpened.includes(i)){
				this.legendOpened = _.without(this.legendOpened, i);
			}else{
				this.legendOpened.push(i)
			}
		},
		isLegendOpened(i){
			return this.legendOpened.includes(i)
		},
		toggleLayer(layer){
			delete this.sliders[layer]
			this.sliderChange(layer)
			this.$store.dispatch('toggle_layer', layer)
		},
		sliderChange(layer){
			let opacity = typeof this.sliders[layer] == 'undefined' ? null : this.sliders[layer]
			this.$store.dispatch('change_layer_opacity', {'layer_id':layer, 'opacity': opacity})
		},
		layerHasLineOrPolygon(_layer){
			let hasLineOrPolygon = false
			if(this.$store.state.ui.layers_data[_layer] && this.$store.state.ui.layers_data[_layer].features){
				this.$store.state.ui.layers_data[_layer].features.forEach(function(feature){
					if(feature.geometry && feature.geometry.type && ["MultiPolygon", "Polygon", "Line"].includes(feature.geometry.type)) hasLineOrPolygon = true
				})

			}else{
				hasLineOrPolygon = true

			}
			return hasLineOrPolygon;
		},
		reset_filters(){
			this.$store.dispatch('resetPrimaryLayers');
			this.$store.dispatch('resetParamsLayers');
		},
		toggle_markers(){
			this.$store.dispatch('toggle_markers')
		},
		toggle_heatmap(){
			this.$store.dispatch('toggle_heatmap')
		},
		toggle_heatmap_pvrmeta(){
			this.$store.dispatch('toggle_heatmap_pvrmeta')
		}
	},
	computed:{
		canHeatmapPvr(){
			let can = false
			let familles = this.$store.getters.getFamillesParams;
			_.forEach(familles, function(famille){
				if(famille.pvr_metha === '1') can = true
			})
			return can
		}
	},
	components: {
	    vueCustomScrollbar,
	    VueSlider,
	    Arbo,
	    Checkbox,
	    FiltreHeader,
	    CarreLegende
	}
}