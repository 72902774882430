export default {
    name: 'CarreLegende',
    props: {
        border_color: {
            type: String,
            required: false,
            default: '#000'
        },
        border_width: {
            type: String,
            required: false,
            default: '1'
        },
        background_color: {
            type: String,
            required: false,
            default: '#fff'
        },
        width: {
            type: String,
            required: false,
            default: '24'
        },
        height: {
            type: String,
            required: false,
            default: '24'
        }
    }
}
