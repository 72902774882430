import Teleport from 'vue2-teleport';
import Loading from '../../common/ui/loading_inex.vue'

export default {
	name: 'PdfComputing',
	components: {
		Teleport,
		Loading
	},
	data() {
		return {}
	}
}
