import {MapElementFactory} from 'vue2-google-maps'
import axios from '../../common/axios.js'
import get from 'lodash/get'
import * as turf from '@turf/turf'
 
export default MapElementFactory({
  name: 'geojsonLayer',
  data() {
    return {
      data: null,
      clickable_general: false,
      style: {
        strokeOpacity: 0.5,
        fillOpacity: 0.2
      },
      selected_style: {
        strokeColor: '#FF0000',
        fillColor: '#0000FF',
        fillOpacity: 0.1,
        strokeWeight: 2,
        strokeOpacity: 0.5,
        zIndex: 100
      },
      firstLoad: false,
      open: false,
      geoJsonData: null,
      zones_selected: this.zonesDefaultSelected ? this.zonesDefaultSelected.zones : [],
      axios_source: null,
      last_geoJsonActif_str:'vide',
      layer_to_use: null,          //AS--> Stock les infos du layer à utiliser pour l'affichage
      is_multi: false,             //AS--> Passe à true si c'est un layer de tyupe multiple
      layers_multi: null,          //AS--> Référence tous les calques du mode multi
    }
  },
  ctr: () => google.maps.Data,
  //// The following is optional, but necessary if the constructor takes multiple arguments
  //// e.g. for GroundOverlay
  // ctrArgs: (options, otherProps) => [options],
  events: [],
 
  // Mapped Props will automatically set up
  //   this.$watch('propertyName', (v) => instance.setPropertyName(v))
  //
  // If you specify `twoWay`, then it also sets up:
  //   google.maps.event.addListener(instance, 'propertyName_changed', () => {
  //     this.$emit('propertyName_changed', instance.getPropertyName())
  //   })
  //
  // If you specify `noBind`, then neither will be set up. You should manually
  // create your watchers in `afterCreate()`.
  mappedProps: {
    // routeIndex: { type: Number },
    // options: { type: Object },
    // panel: { },
    // directions: { type: Object },
    //// If you have a property that comes with a `_changed` event,
    //// you can specify `twoWay` to automatically bind the event, e.g. Map's `zoom`:
    // zoom: {type: Number, twoWay: true}
  },
  // Any other properties you want to bind. Note: Must be in Object notation
  props: {
    mode: { type : String },
    layer_num: { type: Number },
    zindex: { type: Number },
    layer: { type: Object },
    zonesDefaultSelected: { type: Object },
    aireSupp: { type: Boolean}
  },
  // Actions you want to perform before creating the object instance using the
  // provided constructor (for example, you can modify the `options` object).
  // If you return a promise, execution will suspend until the promise resolves
  beforeCreate (options) {},

  // Actions to perform after creating the object instance.
  afterCreate (dataInstance) {

    let self = this;

    this.axios_source = axios.CancelToken.source();

    //AS--> Stock l'instance de maps.data
    this.dataInstance = dataInstance;
    
    this.layer_to_use = _.cloneDeep(this.layer);
    this.clickable_general = this.isSelectionnable || this.layer_to_use.couleurs?.[0]?.clickable || false;

    //AS--> Défini les styles par défaut
    this.updateDefaultStyle()

    if(this.layer_to_use.type == 'multi'){

      //AS--> Stock les valeurs du multi
      this.is_multi = true;
      this.layers_multi = this.layer_to_use.layers;

      //AS--> Défini le calque à utiliser
      this.switchLayerMulti();
    }

    //AS--> Evénement de clic sur une feature
    self.dataInstance.addListener('click', self.onClick);
    self.dataInstance.addListener('mouseover', self.onMouseover);
    self.dataInstance.addListener('mouseout', self.onMouseout);


    //AS--> Vérifie à l'initialisation si c'est le calque à afficher en fonction du mode de 'content' actuel
    if(self.mode == 'affiche'){
      this.open = this.$store.state.ui.layers_opened.includes(this.layer.layer);

    }else if(self.mode == 'filtres'){
      this.open = this.zonesDefaultSelected && this.zonesDefaultSelected.layer && this.zonesDefaultSelected.layer == this.layer_to_use.layer;

    }

    if(self.layer_to_use.trait_couleur)  self.style.strokeColor   = self.layer_to_use.trait_couleur;
    if(self.layer_to_use.fond_couleur)   self.style.fillColor     = self.layer_to_use.fond_couleur;


    this.loadLayer();

  },

  watch:{
    //AS--> Surveille le switch du calque à afficher en mode 'Affiche'
    "$store.state.ui.layers_opened" : function(){
      if(this.mode == 'affiche'){
        this.updateOpened();
      }
    },
    //AS--> Surveille le switch du calque à afficher en mode 'Filtres'
    "$store.state.ui.map_layer_opened" : function(){
      if(this.mode == 'filtres'){
        this.zones_selected = [];
        this.$emit('zonesChanged', this.zones_selected);
        this.updateOpened();
      }
    },
    //AS--> Surveille le switch d'affichage du mode principal
    "$store.state.ui.content" : function(){
      this.updateOpened();
    },
    //AS--> Surveille les changements d'affichage de la carte Google
    "$store.state.ui.map_zone_google.geoJsonActif" : function(){
      if((this.layer_to_use.update && this.layer_to_use.update == 'interactif') || this.is_multi)
        this.loadLayer(true);
    },
    //AS--> Surveille les changements d'activation de l'outil pour pointer une coordonnée sur la carte
    "$store.state.ui.map_get_coords" : function(after){
      this.updateStyle()
    },
    //AS--> Surveille les changements d'affichage de la carte Google
    "$store.state.parcelle.parcelle_on_id_old" : function(after){
      this.updateStyle()
    },
    //AS--> Surveille les changements d'affichage de la carte Google
    "$store.state.ui.layers_legendes_opened" : function(after){
      this.updateStyle()
    },
    //AS--> Surveille les changements d'opacité
    "$store.state.ui.layers_opacities" : function(after){
      this.updateStyle()
    },
    //AS--> Surveille la fin du premier chargemenr des données
    "$store.state.ui.first_data_loaded" : function(after){
      this.loadLayer()
    },
    //AS--> Surveille les changements de droits d'accès au clique sur les calques
    "$store.getters.canClickOnLayers" : function(after){
      this.updateDefaultStyle()
      this.updateStyle()
    }

  },

  computed:{
    isSelectionnable(){
      // console.log('isSelectionnable *************** ', this.mode, this.$store.state.ui.content, this.mode == 'filtres' && this.$store.state.ui.content == 'map');
      return this.mode == 'filtres' && this.$store.state.ui.content == 'map';
    },
    affichable(){
      return this.open && (this.layer_to_use.type != 'api' || (this.layer_to_use.zone_maxi == 0 || this.layer_to_use.zone_maxi > this.$store.state.ui.map_zone_google.areaActif))
    },
    isClickable(){
      return this.$store.getters.canClickOnLayers && (this.isSelectionnable || this.layer_to_use?.couleurs[0]?.clickable)
    }
  },

  methods: {
    updateDefaultStyle(){
      this.dataInstance.setStyle({
        strokeWeight: 1,
        strokeOpacity: 0,
        fillOpacity: 0,
        clickable: this.clickable_general
      });
    },
    switchLayerMulti(){

      //AS--> Détermine le calque à afficher
      let zone_size = this.$store.state.ui.map_zone_google.areaActif;
      
      if(typeof zone_size != 'undefined'){
        let layer_selected = null;
        this.layers_multi.forEach(function(layer){
          if(layer.max == '') layer.max = 99999999999999999999999999999999999;
          if(layer.max > zone_size && (layer_selected == null || layer.max < layer_selected.max)) layer_selected = layer;
        })

        //AS--> S'il faut changer le calque actif
        if(this.layer_to_use == null || this.layer_to_use.layer != layer_selected._id){
          //AS--> Annule la requête en cours si elle s'exécute toujours
          if (undefined != this.axios_source) this.axios_source.cancel('Cancel');
          this.$store.dispatch('layer_loaded', this.layer.layer);

          this.$store.dispatch('layer_zoom_alert_del', this.layer.layer);
          layer_selected.layer = layer_selected._id;
          this.off();
          this.layer_to_use          = layer_selected;
          this.firstLoad             = false;
          this.geoJsonData           = null;
          this.last_geoJsonActif_str = null;
        }

      }

    },
    loadLayer(update){
      let self = this;

      if(this.$store.state.ui.screenPdfOn) return;

      if(!this.$store.state.ui.first_data_loaded && !this.$store.getters.areaTooBig) return
 
      if(this.is_multi){
        //AS--> Défini le calque à utiliser
        this.switchLayerMulti();
      }

      if(this.layer_to_use.update == 'interactif' && !this.firstLoad){
         self.firstLoad = true;
         self.$store.dispatch('layer_loaded', self.layer.layer);
      }

      //AS--> Chargement des données si elles n'ont pas encore été téléchargées
      if( 
        (
          this.layer_to_use.update == 'static' 
          && typeof this.$store.state.ui.layers_data[this.layer_to_use.layer] == 'undefined'
          && !this.firstLoad
        ) 
        || 
        (
          this.layer_to_use.update == 'interactif' 
          && update 
          && this.open
        )){

        //AS--> Dans le cas d'un calque interactif, si on l'a déjà chargé et que la zone Active n'a pas changée, on ne recharge pas les données
        if(this.layer_to_use.update == 'interactif' && this.last_geoJsonActif_str == JSON.stringify(this.$store.state.ui.map_zone_google.geoJsonActif)) return;
        
        this.last_geoJsonActif_str = JSON.stringify(this.$store.state.ui.map_zone_google.geoJsonActif);

        this.firstLoad = true;
        
        let url          = '';
        let method       = 'get';
        let axios_params = [];
        let axios_data   = {};

        //AS--> Si c'est un calque static
        if(this.layer_to_use.update == 'static'){

          //AS--> Définition de l'URL
          this.$store.dispatch('layer_unloaded', this.layer.layer);
          url = this.layer_to_use.type == 'interne' ? 'sourcing_api/layers.php?action=getSourcingLayerPaths&layer_id=' + this.layer_to_use.layer : this.layer_to_use.url;
          axios_params.push(url);

        //AS--> Si c'est un calque dynamique et qu'on a une zone d'affichage définie
        }else if(this.$store.state.ui.map_zone_google.geoJsonActif != null){

          //AS--> Définition de l'URL
          url = this.layer_to_use.type == 'interne' ? 'sourcing_api/layers.php?action=getLayerGoogleMap' : this.layer_to_use.url;
          method = this.layer_to_use.method ? this.layer_to_use.method.toLowerCase() : 'get';
          if(this.layer_to_use.type == 'interne') method = 'post';

          //AS--> S'il n'est pas demandé d'afficher le calque, on l'indique comme chargé et on s'arrête là
          if(!this.open){
            self.$store.dispatch('layer_loaded', self.layer.layer);
            return;
          }

          //AS--> Si on n'est pas assez zoomé, on suprime l'existant et on s'arête là
          if(this.layer_to_use.zone_maxi != 0 && this.layer_to_use.zone_maxi < this.$store.state.ui.map_zone_google.areaActif){
            this.$store.dispatch('layer_zoom_alert_add', this.layer.layer);
            self.$store.dispatch('layer_loaded', self.layer.layer);
            this.off();
            return;
          }

          this.$store.dispatch('layer_zoom_alert_del', this.layer.layer);
          this.$store.dispatch('layer_unloaded', this.layer.layer);

          let polygon      = this.$store.state.ui.map_zone_google.polygon;
          // console.log(this.$store.state.ui.map_zone_google.bounds);
          // console.log(this.$store.state.ui.map_zone_google.bounds.toString());
          // console.log(this.$store.state.ui.map_zone_google.bounds.toUrlValue());
          let bbox         = this.$store.state.ui.map_zone_google.bounds.toUrlValue();
          let geoJsonActif = _.clone(this.$store.state.ui.map_zone_google.geoJsonActif);

          //AS--> Réduction du geoJsonActif si on est en paramètres Get
          if(geoJsonActif != null){
            let maxPoints = method == 'get' ? 45 : 1000;
            let options = {tolerance: 0.0005, highQuality: false, mutate: true};
            while(turf.coordAll(geoJsonActif).length > maxPoints){
              options.tolerance = options.tolerance + 0.0005;
              geoJsonActif = turf.simplify(geoJsonActif, options);
            }
          }

          //AS--> Transformation pour le format Polygon2D
          let geoJsonActifPolygon2d = [];
          if(geoJsonActif.coordinates[0].length){
            geoJsonActif.coordinates[0].forEach(function(coord){
              geoJsonActifPolygon2d.push('('+coord[1]+','+coord[0]+')');
            })
          }
          geoJsonActifPolygon2d = geoJsonActifPolygon2d.join(',');

          url = url.replace('[-zone-]', JSON.stringify(polygon));
          url = url.replace('[-zoneActive-]', JSON.stringify(geoJsonActif));
          url = url.replace('[-zoneActivePolygon2d-]', geoJsonActifPolygon2d);
          url = url.replace('[-bbox-]', bbox);
          axios_params.push(url);

          if(method == 'post'){
            if(this.layer_to_use.type == 'interne'){
              axios_data.layer_id = this.layer_to_use.layer;
              axios_data.bounds   = this.$store.state.ui.map_zone_google.geoJsonActif;
              axios_data.zoom     = this.$store.state.ui.map_zone_google.zoom;
            }else{
              axios_data = _.clone(this.layer_to_use.post_datas);
              _.forEach(axios_data, function(v, l){
                console.log('v', v);
                if(v == '[-zone-]'){
                  axios_data[l] = polygon;

                }else if(v == '[-zoneActive-]'){
                  axios_data[l] = geoJsonActif;

                }else if(v == '[-zoneActivePolygon2d-]'){
                  axios_data[l] = geoJsonActifPolygon2d;
                  
                }

              })
            }
            axios_params.push(axios_data);
          }

        }

        //AS--> Annule la requête en cours si elle s'exécute toujours
        if (undefined != self.axios_source) self.axios_source.cancel('Cancel');

        //AS--> Envoie à Axios la référence du token d'annulation
        self.axios_source = axios.CancelToken.source();
        let axios_config = {
          cancelToken: self.axios_source.token,
          retry: 1000
        }
        axios_params.push(axios_config);

        this.$store.commit('UI_ADD_LOADING_LAYER');
        
        axios[method].apply(this, axios_params)
          .then(function (response) {
            self.$store.commit('UI_REMOVE_LOADING_LAYER');

            //AS--> Vide le calque en cours
            self.off();

            self.data = response.data;

            self.$store.dispatch('layer_loaded', self.layer.layer);
            self.$store.commit('UI_SET_LAYER_DATA', {layer_num: self.layer_to_use.layer, data: self.data});

            self.on();
            if(self.mode == 'filtres' && self.layer_to_use.type != 'api') self.updatedSelected();

          }).catch(function (error) {
            self.$store.commit('UI_REMOVE_LOADING_LAYER');

            //AS--> Si ce n'est pas un avortement, on dit que le calque est chargé
            if(typeof error.message == 'undefined' || error.message != 'Cancel')
              self.$store.dispatch('layer_loaded', self.layer.layer);
            
          })

      //AS--> Mise en place des données déjà téléchargées
      }else if(this.layer_to_use.update != 'static' || this.geoJsonData == null){

        this.data = this.$store.state.ui.layers_data[this.layer_to_use.layer];
        this.on();
        if(self.mode == 'filtres') self.updatedSelected();

      }
    },
    updateOpened(){
      let self = this;
      let old_open = this.open;

      //AS--> En mode 'result', regarde sur this.$store.state.ui.layers_opened
      if(self.mode == 'affiche'){
        this.open = this.$store.state.ui.layers_opened.includes(this.layer.layer);
      } 
      else if(self.mode == 'filtres'){
        this.open = this.$store.state.ui.map_layer_opened == this.layer.layer;
      }

      if(this.open != old_open){
        if(this.open){
          this.loadLayer(true);
          this.on();
        }else{
          this.off();
        }
      }
      
      this.updatedSelected();

    },
    on(){
      let self = this;

      //AS--> Si on a des data et qu'il faut les dessiner
      if(this.data != null && this.affichable){

        //AS--> Récupère les features interprétée par GoogleMap après les avoir injectées sur la carte
        this.geoJsonData = this.dataInstance.addGeoJson(this.data);

        //AS--> Pour chaque Feature créée par GoogleMap, définition du style spécifique
        this.updateStyle()

      }
    },
    off(){
      let self = this;

      //AS--> Pour chaque Feature créée par GoogleMap, suppression de la map
      if(this.geoJsonData){
        this.geoJsonData.forEach(function(feature){
          self.dataInstance.remove(feature);
        })
      }
    },

    updateStyle(){
      let self = this;

      if(this.geoJsonData && this.geoJsonData.length){
        //AS--> Pour chaque Feature créée par GoogleMap, définition du style spécifique
        this.geoJsonData.forEach(function(feature){
          
          self.getStyleFeature(feature);

        })
      }
    },

    getStyleFeature(feature){
      let self = this;

      let style        = _.clone(self.style);
      let featureStyle = this.getFeatureStyle(feature);

      if(feature.getGeometry().getType() == 'LineString'){

        //AS--> Si le layer possède une définition des couleurs à utiliser
        if(featureStyle){
          style = _.assign(style, featureStyle);

        }else{
          style.strokeWeight = 1.5;
          style.strokeOpacity = 1;
        }

      }else if(feature.getGeometry().getType() == 'Point'){
        let icon_url = '/theme/annuaire/img/point_bleu.png'
        let anchor   = new google.maps.Point(18,18)

        if(featureStyle && featureStyle.icon){
          icon_url = featureStyle.icon.substring(0, 4) != 'http' ? '/sourcing_icons/'+featureStyle.icon : featureStyle.icon
        }
        if(featureStyle && featureStyle.anchor){
          anchor   = new google.maps.Point(featureStyle.anchor[0], featureStyle.anchor[1])
        }

        style.icon = {
          // path: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z",
          // fillOpacity: 0,
          // strokeOpacity: 0,
          // strokeWeight: 4,
          // anchor: new google.maps.Point(0,0),
          // scale: .025,
          // rotation: 0
          anchor: anchor,
          url: icon_url
        }
        // if(self.layer.fond_couleur){
        //   style.icon.fillColor   = style.fillColor;
        //   style.icon.fillOpacity = 0.9;
        // }
        // if(self.layer.trait_couleur){
        //   style.icon.strokeColor   = style.strokeColor;
        //   style.icon.strokeOpacity = 0.9;
        // }
      }else{

        //AS--> Si le layer possède une définition des couleurs à utiliser
        if(featureStyle){
          style = _.assign(style, featureStyle);
        }

        //AS--> Défini si la feature est clicable
        if(!self.isSelectionnable){

          //AS--> S'il y a toutes les infos pour définir une parcelle
          if(this.$store.state.ui.sourcing.parcelles_clicables && feature.getProperty('code_dep') && feature.getProperty('code_com') && feature.getProperty('code_arr') && feature.getProperty('com_abs') && feature.getProperty('section') && feature.getProperty('numero')){
            style['clickable'] = self.isClickable && !self.$store.state.ui.map_get_coords && !this.$store.getters.areMapToolsActive;
            //AS--> Défini la feature comme active
            let parcelle_id = feature.getProperty('code_dep') + feature.getProperty('code_com') + feature.getProperty('code_arr') + feature.getProperty('com_abs') + feature.getProperty('section') + feature.getProperty('numero')
            if(self.$store.state.parcelle.parcelle_on_id == parcelle_id || self.$store.state.parcelle.parcelle_on_id_old == parcelle_id){
                style['strokeOpacity'] = 0.8
                style['fillOpacity']   = 0.8
                style['zIndex']        = 1000
            }
          }
          
        }

      }

      //AS--> Vérifie si la parcelle présente une propriété qui la défini comme clickable
      if(feature.getProperty('clickable') && this.isClickable){
          style['clickable'] = true
      }

      //AS--> Vérifie s'il faut masquer cette feature
      if(self.layer_to_use.couleurs && self.layer_to_use.couleurs.length){

        self.layer_to_use.couleurs.forEach(function(c, i){

          let legende_identifiant = self.layer_to_use.layer ? self.layer_to_use.layer : self.layer_to_use.id
          legende_identifiant += '_'+i

          //AS--> Si la définition de la couleur précise bien la variable de référence et ses valeurs possibles
          if(c.variable && (c.variable == 'all' || (c.valeurs && c.valeurs.length > 0))){

            let value = self.getPropertyValue(feature, c.variable)
            if(c.variable == 'all' || (value != null && c.valeurs.includes(value))){
              style['visible'] = !self.$store.state.ui.layers_legendes_opened.includes(legende_identifiant)
            }
          }
        })
      }

      //AS--> Vérifie si une opacité est définie par l'utilisateur => basé sur le layer principal et pas layer_to_use
      if(typeof self.$store.state.ui.layers_opacities[self.layer.layer] != 'undefined'){
        let fillOpacity = self.$store.state.ui.layers_opacities[self.layer.layer]/100
        
        let strokeOpacity = fillOpacity * 1.3
        if(strokeOpacity > 1) strokeOpacity = 1

        if(typeof featureStyle.fillOpacity == 'undefined' || featureStyle.fillOpacity > 0) style['fillOpacity']   = fillOpacity
        if(typeof featureStyle.strokeOpacity == 'undefined' || featureStyle.strokeOpacity > 0) style['strokeOpacity'] = strokeOpacity
      }

      style['zIndex'] = feature.getGeometry().getType() == 'Point' ? 800-this.zindex : 500-this.zindex

      //AS--> Si clickable n'est pas précisé, on vérifie si la feature contient du contenu à afficher et sinon, on passe clickable à false
      if(typeof style.clickable == 'undefined'){
        if(this.clickable_general){
          let content = this.getFeatureContent(feature)
          style.clickable = content ? true : false
        }else{
          style.clickable = false
        }
      }

      self.dataInstance.overrideStyle(feature, style);

    },

    getFeatureStyle(feature){
      let self = this
      let trouve = false;

      if(self.layer_to_use.couleurs && self.layer_to_use.couleurs.length){
        
        self.layer_to_use.couleurs.forEach(function(c, i){

          //AS--> Si la définition de la couleur précise bien la variable de référence et ses valeurs possibles
          if(c.variable && (c.variable == 'all' || (c.valeurs && c.valeurs.length > 0))){

            let value = self.getPropertyValue(feature, c.variable)
            if(c.variable == 'all' || (value != null && c.valeurs.includes(value))){
              trouve = c.style

            }
          }else{
            if(c.style){
              trouve = c.style
            }
          }

        })

      }
      return trouve
    },

    //AS--> Récupère la valeur d'une variable de feature. La variable peut être définie avec des '.'. Par exemple "proprietaires.0.groupe_personne"
    getPropertyValue(feature, variable){
      if(variable == 'all') return true

      //AS--> On vérifie s'il y a des '.' dans la définition de la variable. On split d'après le premier point pour récupérer le préfixe
      let value   = null
      let prefixe = variable.split('.')[0]
      let base    = feature.getProperty(prefixe)
      if(base){
        let property      = {}
        property[prefixe] = base

        //AS--> Remplace dans path '.n*.' par '[n*]'
        let path          = variable
        path  = path.replace(/\.(n*)\./g, '[$1]')
        
        value = get(property, path)

      }

      return value
      
    },

    //AS--> Fonction de clic sur une feature
    onClick(e){
      let self = this;

      //AS--> Si les features sont sélectionnables pour les filtres
      if(self.isSelectionnable){

        console.log('isSelectionnable')

        if(self.$store.state.ui.content != 'map') return;

        //AS--> En mode filtre uniquement et quand ce n'est pas une ligne
        if(self.mode == 'filtres' && e.feature.getGeometry().getType() != 'LineString'){

          //AS--> Id MongoDb de la featue
          let _id = e.feature.getId();

          //AS--> Si la feature fait déjà parti de la sélection, on la retire
          if(self.zones_selected.includes(_id)){
            self.zones_selected.splice(self.zones_selected.indexOf(_id), 1);

          //AS--> Sinon, on l'ajoute à la sélection
          }else{
            self.zones_selected.push(_id);

          }

          //AS--> Retourne la sélection des features effectuée aux parents
          this.$emit('zonesChanged', self.zones_selected);
        }

        //AS--> Modifie l'aspect esthétique des features sélectionnées
        setTimeout(function(){
          self.updatedSelected();
        },0);
      }else{

        //AS--> Si l'utilisateur ne peut pas récupérer les informations de la feature, parce qu'un outil est en cours d'utilisation par exemple, on ne fait rien
        if(!this.isClickable) return;

        let feature = e.feature;

        //AS--> Cas d'une parcelle
        if(this.$store.state.ui.sourcing.parcelles_clicables && feature.getProperty('code_dep') && feature.getProperty('code_com') && feature.getProperty('code_arr') && feature.getProperty('com_abs') && feature.getProperty('section') && feature.getProperty('numero')){
  
          //AS--> Retire le mode fullscreen pour afficher la parcelle          
          if(self.$store.state.ui.full_screen) self.$store.dispatch('toggle_full_screen')

          let identifiant = feature.getProperty('code_dep')+feature.getProperty('code_com')+feature.getProperty('code_arr')+feature.getProperty('com_abs')+feature.getProperty('section')+feature.getProperty('numero')
          //AS--> Déclare la feature comme étant en on
          this.$store.dispatch('setParcelleOnId', identifiant);

        //AS--> Si ce n'est pas une parcelle, on regarde si la feature possède des informations à afficher
        } else{

          // this.$store.commit('UI_SET_MAP_CUSTOM_INFO_WINDOW', null)

          let content = this.getFeatureContent(feature)

          //AS--> S'il y a du contenu à afficher
          if(content){
            let info = {
              position : {lat: e.latLng.lat(), lng: e.latLng.lng()},
              class    : 'infos_calques',
              contenu  : content.join(''),
              layer    : self.layer_to_use.layer
            }
            // this.$store.commit('UI_SET_MAP_CUSTOM_INFO_WINDOW', info)
            this.$store.dispatch('add_map_custom_info_window_multiple', info)
          }

        }

      }

    },

    getFeatureContent(feature){
      let hasName = false
      let content = []
      feature.forEachProperty(function(p,k){

        //AS--> Si on détecte un nom
        if(['nom','name','title'].includes(k)){
          content.unshift('<h3>'+p+'</h3>')
          hasName = true

        }else if(k != 'clickable'){

          //AS--> Si le contenu est un markdown d'url
          let fullLinkOnlyRegex = /\[([\s\S]+)\]\((https?:\/\/\S+)\)/
          if(typeof p == 'string' && p.match(fullLinkOnlyRegex)){
            let _url = p.match(fullLinkOnlyRegex)
            content.push('<div class="link"><a href="'+_url[2]+'" target="_blank">'+_url[1]+' <img src="/img/link.svg" /></a></div>')
          }
          
          //AS--> Si le contenu est classique
          else{
            content.push('<div class="property"><span class="title">'+k+'</span><span class="content">'+p+'</span></div>')
          }

        }
      })

      //AS--> Ajoute le nom du calque si aucun nom n'a été trouvé dans les propriétés
      if(!hasName) content.unshift('<h3>'+this.layer_to_use.nom+'</h3>')

      return content.length ? content : false
    },

    //AS--> Fonction de mouse over sur une feature
    onMouseover(e){
      let self = this;
      let feature = e.feature;

      //AS--> Cas d'une parcelle
      if(this.$store.state.ui.sourcing.parcelles_clicables && feature.getProperty('code_dep') && feature.getProperty('code_com') && feature.getProperty('code_arr') && feature.getProperty('com_abs') && feature.getProperty('section') && feature.getProperty('numero')){

        //AS--> Déclare la feature comme étant en over
        this.$store.dispatch('setFeatureOver', feature);

        //AS--> Passe la feature en style over
        let over_style = {
          strokeOpacity: 0.8,
          fillOpacity: 0.8,
          zIndex: 1000
        };
        self.dataInstance.overrideStyle(feature, over_style);

      }
      
    },

    //AS--> Fonction de mouse out sur une feature
    onMouseout(e){
      let self = this;
      let feature = e.feature;

      //AS--> Cas d'une parcelle
      if(this.$store.state.ui.sourcing.parcelles_clicables && feature.getProperty('code_dep') && feature.getProperty('code_com') && feature.getProperty('code_arr') && feature.getProperty('com_abs') && feature.getProperty('section') && feature.getProperty('numero')){

        //AS--> Retire la parcelle du focus
        this.$store.dispatch('setFeatureOver', null);


        //AS--> Remets le style normal
        let parcelle_id = feature.getProperty('code_dep') + feature.getProperty('code_com') + feature.getProperty('code_arr') + feature.getProperty('com_abs') + feature.getProperty('section') + feature.getProperty('numero')
        if(self.$store.state.parcelle.parcelle_on_id != parcelle_id && self.$store.state.parcelle.parcelle_on_id_old != parcelle_id){
          let over_style = {
            fillOpacity: 0.2,
            strokeOpacity: 0.5
          };

          //AS--> Vérifie si une opacité est définie par l'utilisateur => basé sur le layer principal et pas layer_to_use
          if(typeof self.$store.state.ui.layers_opacities[self.layer.layer] != 'undefined'){
            let fillOpacity = self.$store.state.ui.layers_opacities[self.layer.layer]/100
            
            let strokeOpacity = fillOpacity * 1.3
            if(strokeOpacity > 1) strokeOpacity = 1

            over_style['fillOpacity']   = fillOpacity
            over_style['strokeOpacity'] = strokeOpacity
          }
          over_style['zIndex'] = 500-this.zindex
          self.dataInstance.overrideStyle(feature, over_style);
        }
      }
      
    },


    updatedSelected(){
      let self = this;

      if(self.geoJsonData != null){
        
        let selected_style = _.clone(self.selected_style);
        if(self.aireSupp){
          selected_style.strokeColor = '#ff8100'
          selected_style.fillColor   = '#ff8100'
        }

        self.geoJsonData.forEach(function(feature){

          //AS--> Si on est en mode GeoJson de 'Filtre' ...
          if(self.mode == 'filtres'){

            //AS--> ... et qu'on affiche le filtre sur la page de résultat et que la feature n'est pas dans les sélectionnées, on la retire
            if(self.$store.state.ui.content == 'result' && !self.zones_selected.includes(feature.getId())){
              self.dataInstance.remove(feature);
            
            }else{

              if(feature.getGeometry().getType() != 'LineString'){

                //AS--> Si la feature est sélectionnée, on lui affecte le style spécial
                if(self.zones_selected.includes(feature.getId())){
                  self.dataInstance.overrideStyle(feature, selected_style);

                //AS--> Sinon, on lui affecte le style par défaut
                }else{
                  self.dataInstance.overrideStyle(feature, self.style);

                }
              }
            }
          }

        })

        
        if(self.mode == 'filtres'){
          //AS--> Enregistre sur Filters la géométrie de toutes les features sélectionnées
          let zones_selected_polygons = [];
          if(self.zones_selected){
            this.data.features.forEach(function(feature){
              if(self.zones_selected.includes(feature.id)){
                if(feature.geometry.type == 'Polygon'){
                  zones_selected_polygons.push(feature.geometry.coordinates);
                }
                if(feature.geometry.type == 'MultiPolygon'){
                  zones_selected_polygons = [].concat(zones_selected_polygons, feature.geometry.coordinates);
                }
              }
            })
          }
          this.$store.commit('FILTERS_SET_ZONES_SELECTED_POLYGONS', zones_selected_polygons)
        }

      }
    }
  }
})