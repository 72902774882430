import _ from 'lodash'
import vSelect from 'vue-select'
import Arbo from './blocs/arbo.vue'
import axios from '../common/axios.js'
import Debounce from 'lodash/debounce'

import Connection from './modals/connection.vue';
import Password from './modals/password.vue';
import SignIn from './modals/signin.vue';
import PhoneCode from './modals/phone_code.vue';
import PhoneValidation from './modals/phone_validation.vue';

export default {
	data(){
		return {
			show_arbo: false,
			range: {value: 200, label: 'Rayon : 200 km'},
			companies: [],
			cities: [],
			filtre_company: null,
			filtre_city: null,
			position: {lat: null, lng: null},
			companyQ: ''
		}
	},
	created(){
		let self = this
		//RZ--> Ajout un événement sur le body pour fermer la modale
		document.getElementsByTagName("body")[0].addEventListener('click', self.close_modal);
	},
	beforeDestroy: function () {
		var self = this;
		document.getElementsByTagName("body")[0].removeEventListener('click', self.close_modal);
	},
	mounted(){
		//AS--> Si on est sur un type de sourcing ne demandant pas de recherche sur la page d'accueil, on affiche la modale de connexion ou on redirige vars la page de résultats si l'internaute est connecté
		if(['variables'].includes(this.$store.state.ui.sourcing.fonctionnement)){
			if (!this.$store.state.user.user.nom) {
				this.$store.commit('UI_SET_MODAL', { modal: 'connection' })
			} else {
				this.$store.commit('UI_SET_PAGE', 'results')
				this.$store.dispatch('setFilters', [])
			}
		}
	},
	methods:{
		close_modal(event){
			if(event.target.closest('.modal-arbo') == null && event.target.closest('.input-dechets') == null){
				this.show_arbo = false
			}
		},
		// setSelectedCompany(value) {
		// 	let q = value != null ? value.nom : '';
		// 	this.$store.dispatch('setQ', q);
		// },
		setSelectedCity(value){
			let self = this;
			if (value != null) {
				axios.get('/plugin_projet/plugin_projet_iannuaire/get_place_id/'+value.place_id)
		      .then(function (response) {
				self.position = response.data.position
		      	// self.cities = response.data
		      }).catch(function (error) {

		      })
			}else{
				self.position = {}
			}
		},
		search(){
			let filters = {
			    // range: this.range.value,
			    q: this.companyQ	
			}

			if (this.position.lat != null) {
				filters.position = {lat: this.position.lat, lng: this.position.lng}
			}
			
			this.$store.dispatch('setFiltersAccueil', filters);
		},
		change_zone(){
			this.$store.commit('UI_SET_MODAL', {modal: 'alert_subscription'});
		},
		open_arbo(){
			this.show_arbo = !this.show_arbo;
		},
		setFavoris(recherche){
			this.$store.dispatch('setSavedSearch', recherche)
		},
		// search_companies: Debounce(function(search, loading){
		// 	let self = this;
		// 	loading(true)
		// 	self.companyQ = search
		// 	var filtres = new FormData();
  //   		filtres.append('filtres', JSON.stringify({q:self.companyQ, autocompletion: true}));
		// 	axios.post('/plugin_projet/plugin_projet_iannuaire/get_entreprises', filtres)
		//       	.then(function (response) {
		//       		if(response.data != 'CONNEXION'){
		// 	      		self.companies = response.data
		// 				loading(false)
		// 			}
		//       	}).catch(function (error) {
  //     			})
		// }, 500),
		search_villes: Debounce(function(search, loading){
			let self = this;
			loading(true)
			axios.get('/plugin_projet/plugin_projet_iannuaire/places_autocompletion/'+encodeURI(search))
		      .then(function (response) {
		      	self.cities = response.data
					loading(false)
		      }).catch(function (error) {

			})
		}, 500),
		openRefashion(){
			window.open('https://inscription.recycle.refashion.fr/inscription', '_blank')
		}
	},
	computed:{
		customStyle(){
			let image = this.$store.state.ui.sourcing.fond_imgu && this.$store.state.ui.sourcing.fond_imgu.length ? this.$store.state.ui.sourcing.chemin_image+this.$store.state.ui.sourcing.fond_imgu : '/theme/annuaire/img/annuaire.jpg';
			return {'background-image':'url('+image+')'};
		},
		logoClient(){
			return this.$store.state.ui.sourcing.logo_imgu && this.$store.state.ui.sourcing.logo_imgu.length ? this.$store.state.ui.sourcing.chemin_image+this.$store.state.ui.sourcing.logo_imgu : null;
		},
		getDechetLabel(){
			return this.$store.state.filters.main_dechet && this.$store.state.filters.filters.dechets_ids.length > 0 ? this.$store.state.filters.main_dechet : ''
		},
		getSearch(){
			let max = 5;
			let search = []
			let recherches = _.cloneDeep(this.$store.state.user.recherches)
			for(let i in recherches){
				let d = recherches[i]
				d.nom = _.truncate(d.nom, {length: 30})
				search.push(d)
				max--
				if(max == 0) break
			}

			return search.length > 0 ? _.reverse(search) : false
		},
		options(){
			let _options = [
				{value: 1000, 	label: 'Rayon : 1000 km'},
				{value: 750, 	label: 'Rayon : 750 km'},
				{value: 500, 	label: 'Rayon : 500 km'},
				{value: 400, 	label: 'Rayon : 400 km'},
				{value: 300, 	label: 'Rayon : 300 km'},
				{value: 200, 	label: 'Rayon : 200 km'},
				{value: 150, 	label: 'Rayon : 150 km'},
				{value: 100, 	label: 'Rayon : 100 km'},
				{value: 50, 	label: 'Rayon : 50 km'},
				{value: 20, 	label: 'Rayon : 20 km'},
				{value: 10, 	label: 'Rayon : 10 km'}
			];
			let maxi = this.$store.state.ui.sourcing.rayon_maxi > 0 ? this.$store.state.ui.sourcing.rayon_maxi : 200;

			//AS--> Crée le tableau d'options
			let options = [
				{value: maxi, 	label: 'Rayon : '+maxi + ' km'}
			];
				
			//AS--> Ajoute toutes les valeurs inférieures à maxi au tableau des options
			_.forEach(_options, function(item){
				if(item.value < maxi) options.push(item);
			})

			//AS--> Set range sur le maxi
			this.range = {value: maxi, 	label: 'Rayon : '+	maxi + ' km'};

			return options;
		},
		getSourcingPosition(){
			let position_zone = JSON.parse(this.$store.state.ui.sourcing.position_zone)

			let label = position_zone != undefined ? this.$t('search_page_position_center')+position_zone.name : ''

			return label
		},
		getSourcingRayon(){
			let rayon = this.$store.state.ui.sourcing.rayon_maxi > 0 ? this.$store.state.ui.sourcing.rayon_maxi : 200;

			let label = rayon ? this.$t('search_page_rayon')+rayon+'km' : ''

			return label
		}
	},
	components:{
		vSelect,
		Arbo,
		Connection,
		Password,
		SignIn,
		PhoneCode,
		PhoneValidation
	}
}